@font-face {
  font-family: "MuktaMalar-Regular";
  src: url("./assets/fonts/Mukta_Malar/MuktaMalar-Regular.ttf");
}

@font-face {
  font-family: "MuktaMalar-Medium";
  src: url("./assets/fonts/Mukta_Malar/MuktaMalar-Medium.ttf");
}

@font-face {
  font-family: "MuktaMalar-Bold";
  src: url("./assets/fonts/Mukta_Malar/MuktaMalar-Bold.ttf");
}

@font-face {
  font-family: "MuktaMalar-Light";
  src: url("./assets/fonts/Mukta_Malar/MuktaMalar-Light.ttf");
}

body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: MuktaMalar-Regular;
}
button {
  font-family: MuktaMalar-Regular;
}

.swiper-container {
  width: 100%;
  height: 100%;
  @apply bg-mobux-dark-purple;
}
.swiper-container2 {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.swiper-container-carousel{
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.swiper-slide-next, .swiper-slide-prev {
  overflow: hidden;
}
.swiper-slide{
  overflow: hidden;
}

.select-disable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 ;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: white;
}