.swiper-button-next,
.swiper-button-prev {
  @apply text-mobux-green;
  @media (max-width: 639px) {
    display: none;
  }
}
.swiper-button-next{
  transform: translateX(80px);
}
.swiper-button-prev{
  transform: translateX(-80px);
}

.swiper-pagination {
  background-color: black;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 30;
}

.swiper-pagination-bullet {
  @apply bg-white;
}
.swiper-pagination-bullet-active{
  @apply bg-mobux-green;
}

.default-size {
  width: 100%;
  height: 100%;

  @media (min-width: 640px) {
    @apply w-100 h-140;
  }
}

.default-inner-size {
  
  width: 100%;
  height: 95%;

  @media (min-width: 640px) {
    width: 1;
    @apply w-100 h-135;
  }
}
