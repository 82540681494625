.custom-mobile {
    // @apply w-9/12;
    width: 65%;


    @media (max-width: 450px){
        @apply w-9/12
    }

    @media only screen and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3) 
    { 
        width: 60%;
    }
}