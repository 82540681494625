html {
  /* font-size: calc(100% - 20px); */

  font-size: 16px;

  @media (max-width: 640px) {
    font-size: calc(5.5px + 2vmin);
  }
}

.handle-font{

  @media (max-width: 450px) {
    font-size: 1.3rem;
  }
}

.default-size {
  width: 100%;
  height: 100%;

  @media (min-width: 640px) {
    @apply w-100 h-140;
  }
}

.default-swiper-two-size {
  
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: 640px) {
    width: 1;
    @apply w-100 h-135;
  }
}